import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const LostVoterPage = () => (
  <Layout>
    <SEO
      title="Lost voter"
      keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]}
    />
    <article className="slim">
      <h1>Are you trying to vote in an online election?</h1>
      <p>
        You have probably arrived here referred by a link in a social network
        or search engine results page. nVotes is an Online Voting Software and
        Services vendor. As such, <strong>we don’t provide voter support
        directly to voters. Voter support is carried out by the organization
        running the election</strong>, who should include contact information,
        comply with the regulatory provisions and fulfill the duty of
        information and security measures.
      </p>
      <p>
        In relation to personal information about voters, we act as a processor
        on behalf of the election organizer. nVotes is not responsible for the
        privacy or security practices of the election organizer. Please check
        with election organizer about the policies they have in place.
      </p>
    </article>
  </Layout>
)

export default LostVoterPage
